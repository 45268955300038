/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

function IndexNavbar() {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

 
  return (
    <Navbar className={classnames("fixed-top")} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/index"
            target="_self"
            title="องค์กรคริสตจักรคณะคริสเตียนสานสัมพันธ์"
          >
            <img
              alt="..."
              className="nav-logo"
              src={require("assets/img/logo2.png")}
            />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="#locations"
                target="_self"
                title="คริสตจักรภายในคณะ"
              >
                <p>LOCATIONS</p>
              </NavLink>
            </NavItem>
           <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.youtube.com/user/nexusfellowship"
                target="_self"
                title="สื่อมีเดีย"
              >
                <p>Media</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="#resource"
                target="_self"
                title="บทเรียน"
              >
                <p>RESOURCE</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="#whoweare"
                target="_self"
                title="เกี่ยวกับเรา"
              >
                <p>WHO WE ARE</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#login"
                target="_self"
                title="เข้าสู่ระบบ"
              >
                <i className="fa fa-user-circle-o" /><p>Log in</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#login"
                target="_self"
                title="เข้าสู่ระบบ"
              >
                <i className="fa fa-bars" /><p>Menu</p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
