/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionChurch() {

  return (
    <>
      <div className="section section-church">
        <Container>
        <Row>
          <div className="test">
              <h2>คุณคาดหวังอะไรจากเราได้บ้าง</h2>
          </div>
          <div class="text-center">
              <h5>ที่คณะคริสเตียนสานสัมพันธ์ คุณจะได้รับการต้อนรับอย่างอบอุ่นและเป็นกันเองจากคนมากมายที่ยินดีจะพบคุณ เราอยากจะช่วยให้ชีวิตของคุณดีขึ้นและมีความสุขมากขึ้น เพียงแค่คุณมาหาเราเราก็พร้อมที่จะช่วยและเป็นเพื่อนกับคุณ </h5>
          </div>
        </Row>
        <Row>
            <div className="test">
              <h2>ที่รอบนมัสการสุดสัปดาห์ คุณจะได้พบกับ</h2>  
            </div>
        </Row>

        <div class="row">
          <div class="col-md-12 offset-md-2">
          <ul>
            <li>รอบนมัสการ 2 ชั่วโมง</li>
            <li>เพลงนมัสการที่แตะใจของคุณ</li>
            <li>คำแบ่งปันกระตุกความคิดและให้กำลังใจจากพระคัมภีร์</li>
            <li>กลุ่มพบปะพูดคุยหลังอาหารกลางวัน </li>     
          </ul>
          </div>
        </div>

        <Row>
            <div className="test">
              <h2>เริ่มก้าวแรกของคุณ เพื่อค้บพบสิ่งเหล่านี้</h2>  
            </div>
        </Row>
        
        <div class="row">
          <div class="col-md-8 offset-md-2">
          <ul>
            <li><strong>พื้นที่ที่เหมาะกับคุณ</strong> เรามีกลุ่มที่จะช่วยให้คุณเติบโต กลุ่มที่หัวเราะด้วยกัน และร้องไห้ไปกับคุณ เรามีกลุ่มที่หลากหลายไม่ว่าจะเป็นกลุ่มครอบครัว คนทำงาน วัยรุ่น เด็ก กลุ่มกิจกรรม กลุ่มกีฬา และกลุ่มอื่นๆอีกมากมาย!</li>
            <li><strong>โอกาสที่จะสร้างการเปลี่ยนแปลง</strong> พระเจ้าทรงสร้างคุณให้มีทักษะและศักยภาพ เพื่อที่จะสร้างการเปลี่ยนแปลงในชุมชนของคุณและในโลกนี้ รับใช้ร่วมรับเราและมาสร้างการเปลี่ยนแปลงร่วมกัน</li>
            <li><strong>หนทางที่จะเติบโตขึ้น</strong> เราอยากจะพัฒนาคุณให้เติบโตขึ้นทั้งในด้านชีวิตส่วนตัว ครอบครัว และในฐานะผู้ติดตามพระเยซู</li>
          </ul>
          </div>
        </div>
        </Container>
      </div>
    </>
  );
}

export default SectionChurch;
