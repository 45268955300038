/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionChurch() {

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg-worship.jpg") + ")"
        }}
        className="page-background"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-right">
            <h1>นมัสการกับเรา</h1>
            <h4>เรามีการนมัสการออนไลน์ และแปลเพลงนมัสการ ผ่านทาง Youtube Channel<br/>ลองเข้ามาดูและฟังบทเพลงที่จะเป็นกำลังใจให้กับคุณ</h4>
            <br />
            <Button className="btn-round" color="neutral" type="button" href="https://www.youtube.com/user/nexusfellowship" outline>
              ฟังเพลงนมัสการ
            </Button>
          </div>
        </Container>
      </div>

      
    </>
  );
}

export default SectionChurch;
