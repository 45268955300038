/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg.jpg") + ")"
        }}
        className="page-header"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-left">
            <h1>ยินดีต้อนรับ</h1>
            <h4>ไม่ว่าคุณกำลังพบกับอะไร เราอยากให้คุณรู้ว่าชีวิตของคุณมีความหมาย<br></br>และเราอยากช่วยคุณให้ค้นพบความหมายของชีวิตตามแบบที่พระเจ้าสร้างคุณมา</h4>
            <br />
            <Button className="btn-round" color="neutral" type="button" href="/welcome" outline>
              เรียนรู้เพิ่มเติม
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
