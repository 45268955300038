/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
// pages
//import Index from "views/Index.js";
import Index from "views/examples/LandingPage.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import LandingPage0 from "views/examples/LandingPage0.js";
import WelcomePage from "views/examples/WelcomePage.js";
import LocationPage from "views/examples/LocationPage.js";

window.$nxbkk = "คริสตจักรสานสัมพันธ์กรุงเทพ";
window.$nxbkk_detail = "24/137 ซ.ลาดพร้าว 21 ถ.ลาดพร้าว จอมพล จตุจักร";

// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={props => <Index {...props} />} />
      <Route
        path="/landing-page"
        render={props => <LandingPage {...props} />}
      />
      <Route
        path="/examples"
        render={props => <LandingPage0 {...props} />}
      />
      <Route
        path="/welcome"
        render={props => <WelcomePage {...props} />}
      />
      <Route
        path="/locations"
        render={props => <LocationPage {...props} />}
      />
      <Redirect to="/index" />
      
    
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
