/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import SectionExpectation from "views/index-sections/SectionExpectation.js";
import SectionWelcome from "views/index-sections/SectionWelcome.js";
import LocationList from "components/LocationList.js";
import { Button, Container,  Row , Col } from "reactstrap";
import Footer from "components/Footers/DemoFooter.js";
import EarthIcon from '@material-ui/icons/Public';


function LocationPage() {
  
 
  return (
    <>
      <IndexNavbar />
      <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg.jpg") + ")"
        }}
        className="page-header"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          
          <div className ="location-list">
          
          <LocationList />

          </div>
          
        </Container>
      </div>
 

       <div className="section">
        <Container className="text-center">
          <Row>
            <Col className="ml-auto mr-auto text-center">
              <h2>ไม่พบคริสตจักรในพื้นที่ของคุณ?</h2>
              <h4>คุณสามารถติดตามรอบนมัสการออนไลน์ของเราได้ทุกวันอาทิตย์ หรือคุณช่วยบอกเราทีว่าไหนที่เราควรไปเปิดคริสตจักร</h4>  
            </Col>
          </Row>
          <br></br>
          <Row className="justify-content-md-center sharing-area text-center">

            <Col className="text-center" lg="8" md="12">

              <Button
                className="btn-round  ml-2"
                href="https://www.youtube.com/user/nexusfellowship"
                target="_blank"
                color="info"
              >
                <EarthIcon /> นมัสการออนไลน์
              </Button>

              <Button color="info" className="btn-round  ml-2">แนะนำสถานที่กับเรา</Button>

              
              
            </Col>
          </Row>
        </Container>
        </div>
      <Footer/>
    </>
  );
}

export default LocationPage;
