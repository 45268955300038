/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChurchIcon from '@material-ui/icons/HomeRounded';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
  },
  inline: {
    display: 'inline',
  },
}));

// set Style For Panel  (รูปแบบ)
const ExpansionPanel = withStyles({
  root: {
    margin: 'auto',
    maxWidth: '600px',
    maxHeight: '600px',
    padding: '0px',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

// set Style For Panel Summary (ส่วนหัว)
const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },

  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: '0px 8px 0px 8px',

  },
}))(MuiExpansionPanelDetails);

export default function LocationList() {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  const nxbkk = window.$nxbkk ;
  const nxbkk_detail = window.$nxbkk_detail;
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}
  return (
  <div>


      <ExpansionPanel>
          <ExpansionPanelSummary>
              <list-header>คริสตจักรในคณะคริสเตียนสานสัมพันธ์</list-header>
          </ExpansionPanelSummary>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>กรุงเทพมหานคร</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์กรุงเทพ</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">24/137 ซอยลาดพร้าว 21 ถนนลาดพร้าว แขวงจอมพล เขตจตุจักร กรุงเทพฯ 10900</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

                <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์ธนบุรี</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">197 อาคารเน็กซัสธนบุรี ถนนพุทธมณฑลสาย 1 ซอย 9 (ถนนราชมนตรี) แขวงบางด้วน เขตภาษีเจริญ กรุงเทพฯ 10160</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

                <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์พระราม 2</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock"></p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>กำแพงเพชร</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์กำแพงเพชร</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">46/7 ซอย 3 ถนนเจริญสุข ต.ในเมือง อ.เมือง จ.กำแพงเพชร 62000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <ExpansionPanelSummary aria-controls="panel3d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>ขอนแก่น</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์กขอนแก่น</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">77/6 ม2 ต.ในเมือง อ.เมืองขอนแก่น จ.ขอนแก่น 40000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <ExpansionPanelSummary aria-controls="panel4d-content" id="panel4d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>ฉะเชิงเทรา</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์ฉะเชิงเทรา</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">9/60 ถ.ศรีโสธรตัดใหม่ ต.หน้าเมือง อ.เมือง จ.ฉะเชิงเทรา 24000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <ExpansionPanelSummary aria-controls="panel5d-content" id="panel5d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>ชลบุรี</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์ชลบุรี</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">100/13 ม.3 ต.เสม็ด อ.เมืองชลบุรี จ.ชลบุรี 20000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <ExpansionPanelSummary aria-controls="panel6d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>ชัยนาท</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์ชัยนาท</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">229/5 ม.8 ต.แพรกศรีราชา อ.สรรคบุรี จ.ชัยนาท 17140</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>


      <ExpansionPanel square expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <ExpansionPanelSummary aria-controls="panel7d-content" id="panel7d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>เชียงใหม่</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์เชียงใหม่</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">33/1 ถนนทุ่งโฮเต็ล ตำบลวัดเกต อำเภอเมือง จังหวัดเชียงใหม่ 50000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
          <ExpansionPanelSummary aria-controls="panel12d-content" id="panel8d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>นครนายก</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์นครนายก</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">ข/4 351/17 ชลประสิทธิ์ ต.บ้านใหญ่ อ.เมือง นครนายก 26000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
          <ExpansionPanelSummary aria-controls="panel11d-content" id="panel8d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>นครปฐม</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์ศาลายา</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">95 หมู่3 ถนน ศาลายา-นครชัยศรี ต.ศาลายา อ.พุทธมณฑล จ.นครปฐม 73170</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>


      <ExpansionPanel square expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
          <ExpansionPanelSummary aria-controls="panel8d-content" id="panel8d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>นครราชสีมา</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์นครบ้านเหลื่อม</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">10-11 ม.2 อ.บ้านเหลื่อม จ.นครราชสีมา 30350</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์นครราชสีมา</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">234 ถ.จอมสุรางค์ยาตร์ ต.ในเมือง อ.เมือง  จ.นครราชสีมา 30000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />


              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์นครราชสีมา2 หัวทะเล</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">317/23 ถ.เบญจรงค์ ต.ในเมือง อ.เมือง จ.นครราชสีมา 30000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>        

       <ExpansionPanel square expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
          <ExpansionPanelSummary aria-controls="panel9d-content" id="panel9d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>พิจิตร</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์พิจิตร</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">26 ถ.ราษฎร์เกษมอุทิศ ต.ในเมือง อ.เมืองพิจิตรจ.พิจิตร 66000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>


      <ExpansionPanel square expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
          <ExpansionPanelSummary aria-controls="panel9d-content" id="panel9d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>เพชรบูรณ์</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรคันประทีปประชาอุทิศ</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">366/5 ถ.ประชาอุทิศ ต.วัดป่า อ.หล่มสัก จ.เพชรบูรณ์ 67110</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรคันประทีปเพชรบูรณ์</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">3/3 ซอยไทรงามพัฒนา ต.ในเมือง อ.เมือง จ.เพชรบูรณ์ 67000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรคันประทีปวังโป่ง</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">404 ม.14 ต.วังหิน อ.วังโป่ง จ.เพชรบูรณ์ 67240</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์หล่มเก่า</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">24 ม.4 ต.หล่มเก่า อ.หล่มเก่า จ.เพชรบูรณ์</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์เขาค้อ</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">84/3 หมู่ 11 ต.ทุ่งสมอ อ.เขาค้อ จ.เพชรบูรณ์ 67110</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์บึงสามพัน</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">หมู่ 3 ซอย เทศบาล 13 ต.ซับสมอทอด อ.บึงสามพัน จ.เพชรบูรณ์ 67160</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์เมืองเพชรบูรณ์</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">472/6 ถนนเพชรพัฒนา ตำบลในเมือง อำเภอเมือง จังหวัดเพชรบูรณ์ 67000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์วิเชียรบุรี</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">109 ม.10 ต.ท่าโรง อ.วิเชียรบุรี จ.เพชรบูณ์ 67130</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์หล่มสัก</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">201/1 ถ.หล่มสัก หล่มเก่า ต.วัดป่า อ.หล่มสัก จ.เพชรบูรณ์ 67110</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />




          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
          <ExpansionPanelSummary aria-controls="panel14d-content" id="panel10d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>ราชบุรี</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์เมืองราชบุรี</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">433/171 ถ.ศรีสุริยวงศ์​ ต.หน้าเมือง อ.เมือง จ.ราชบุรี 70000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์ราชบุรี (บางแพ)</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">75 หมู่ 7 ต.บ้านเลือก อ.โพธาราม จ.ราชบุรี 70120</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
          <ExpansionPanelSummary aria-controls="panel10d-content" id="panel10d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>เลย</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
              <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์เชียงคาน</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">175/5 ซ7บน ม.1 ต.เชียงคาน อ.เชียงคาน จ.เลย 42110</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์เลย</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">79/24 บ้านนาหนอง ถ.เลย-ด่านซ้าย ต.กุดป่อง อำเภอเมือง จังหวัดเลย 42000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
          <ExpansionPanelSummary aria-controls="panel15d-content" id="panel10d-header" expandIcon={<ExpandMoreIcon />}>
              <list-header>ลพบุรี</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
            <ListItemLink href="#simple-list" alignItems="flex-start">
                  <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">คริสตจักรสานสัมพันธ์ชัยบาดาล</span>
                      <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">79/24 บ้านนาหนอง ถ.เลย-ด่านซ้าย ต.กุดป่อง อำเภอเมือง จังหวัดเลย 42000</p>
                  </div>
              </ListItemLink>

            <Divider component="li" />

          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>
  </div>
  );
}