/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import SectionExpectation from "views/index-sections/SectionExpectation.js";
import SectionWelcome from "views/index-sections/SectionWelcome.js";

import Footer from "components/Footers/DemoFooter.js";


function WelcomePage() {
  
 
  return (
    <>
      <IndexNavbar />
      <SectionWelcome />
      <div className="main">
      	<SectionExpectation />
      </div>
      <Footer/>
    </>
  );
}

export default WelcomePage;
